<template>
<div class="cardParceiros">
<b-container>
    <b-row align-h="center" tabindex="0">
        <Titulo opacityTitle="Clientes" texto="Clientes e Parceiros" />
    </b-row>
    <VueSlickCarousel v-bind="settings"  class="vertical-center slider">
        <div>
            <div class="card-body" tabindex="0">
                <div class="text-card">Automobilístico</div>
                    <div class="espacamento">
                        <div class="primeiraLinha"></div>
                    </div>
                <div class="espacamento1">
                    <b-img
                        :src="require(`@/assets/cards/marelli.png`)"
                        center
                        fluid alt="Icone da Marelli"
                        tabindex="0">
                    ></b-img>
                    <div class="espacamento"></div>
                    <div class="patrocinio">
                        <b-img
                            :src="require(`@/assets/cards/chevrolet.png`)"
                            fluid alt="Icone da empresa Chevrolet"
                            :width="70"
                            center
                            tabindex="0">
                        ></b-img> 
                        <b-img
                            :src="require(`@/assets/cards/ford.png`)"
                            fluid alt="Icone da empresa Ford"
                            center
                            tabindex="0">
                        ></b-img>
                    </div>
                    <div class="patrocinio">
                        <b-img
                            :src="require(`@/assets/cards/fiat.png`)"
                            fluid alt="Icone da empresa Fiat"
                            center
                            tabindex="0">
                        ></b-img>
                        <b-img
                            :src="require(`@/assets/cards/toyota.png`)"
                            :width="45"
                            :height="45"
                            fluid alt="Icone da empresa Toyota"
                            center
                            tabindex="0">
                        ></b-img>     
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="card-body" tabindex="0">
                <div class="text-card">Automobilístico</div>
                <div class="espacamento3">
                    <div class="primeiraLinha"></div>
                </div>
                <b-img
                    :src="require(`@/assets/cards/plascar.png`)"
                    :width="185"
                    :height="185"
                    alt="Icone da empresa Plascar"
                    center
                    fluid
                    tabindex="0"
                ></b-img>
            </div>
        </div>
        <div>
            <div class="card-body" tabindex="0"> 
                <div class="text-card">Mineiração</div>
                <div class="espacamento2">
                    <div class="primeiraLinha"></div>
                </div>
                <b-img
                    :src="require(`@/assets/cards/epiroc.png`)"
                    :width="165"
                    :height="40"
                    alt="Icone da empresa Epiroc"
                    center
                    fluid
                    tabindex="0"
                ></b-img>
            </div>
        </div>   
        <div>
            <div class="card-body" tabindex="0">
                <div class="text-card">Motociclismo</div>
                <div class="espacamento1">
                    <div class="primeiraLinha"></div>
                </div>
                <b-img
                    :src="require(`@/assets/cards/bmp.svg`)"
                    :width="185"
                    :height="185"
                    alt="Icone da empresa Epiroc"
                    center
                    fluid
                    tabindex="0"
                ></b-img>
            </div>
        </div>
        <div>
            <div class="card-body" tabindex="0">
                <div class="text-card">Moveleiro</div>
                <div class="espacamento1">
                    <div class="primeiraLinha"></div>
                </div>
                <b-img
                    :src="require(`@/assets/cards/america.png`)"
                    :width="165"
                    alt="Icone da empresa America"
                    center
                    fluid
                    tabindex="0"
                ></b-img>
            </div>
        </div>

    <template #customPaging="">
      <div class="custom-dot" alt="botoes">
        {{  }}
      </div>
    </template>

    <template #prevArrow="">
      <div class="custom-arrow">
        {{  }}/{{ }}
      </div>
    </template>
    </VueSlickCarousel>
</b-container>
</div>
</template>

<style lang="scss" scoped>
.slider {
    width: 68%;
    margin: 30px auto;
}
.slick-slide img {
    padding-top: 35px;
}
.slick-prev:before{
    content:url("../assets/cards/setaesquerda.png");
}
::v-deep {
.slick-next:before{
    content:url("../assets/cards/setadireita.png");
}
.slick-next{
    width: 0px;
    right: 10px;
}
}
.slick-active {
    opacity:1;
}
.slick-active .custom-dot{
    background: #585858;
    width: 22px;
    height: 3px;
    border-radius: 2px;
}
.custom-dot{
    background: #c5c5c5;
    width: 22px;
    height: 3px;
    border-radius: 2px;
}
.card-body {
    background: rgb(255, 254, 254); 
    border-radius: 5px; 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    height: 430px; 
    margin:30px;
}
.text-card{
    padding-top: 30px;
    font-size: 1.2em;
    font-family: Lato, Arial, Helvetica, sans-serif;
    color: #0A292F;
    text-align: center;
    min-height: 65px;
}
.primeiraLinha{
    width: 75px;
    min-height: 1px;
    background: #777777;
    margin-left: auto;
    margin-right: auto;
}
.patrocinio{
    margin-left: 50px;
    margin-right: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.espacamento{
    height: 10px;
}
.espacamento1{
    height: 90px;
}
.espacamento2{
    height: 100px;
}
.espacamento3{
    height: 70px;
}

@media screen and (max-width: 768px) {
.slider{
    width: 90%;
}
.titulo{
    font-size: 1.2em;
    text-align: center;
}
.text-card{
    font-size: 1em;
    text-align: center;
}
}
@media screen and (min-height: 768px) {
.slider{
    width: 90%;
}
}
</style>
<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Titulo from "@/components/Titulo.vue";

export default {
    name: 'MyComponent',
    components: { VueSlickCarousel, Titulo},
    data() {
      return {
        settings: {
            "dots": false,
            "infinite": true,
            "speed": 600,
            "slidesToShow": 2,
            "slidesToScroll": 1,
            "initialSlide": 0,
            "autoplay": true,
            "autoplaySpeed": 5000,
            "cssEase": "linear",
            "responsive": [
        {
            "breakpoint": 1024,
            "settings": {
            "slidesToShow": 2,
            "slidesToScroll": 1,
            "infinite": true,
            "dots": false
            }
        },
        {
            "breakpoint": 765,
            "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1
            }
        }
    ]
        },
      }
  },
} 
</script>