<template>
  <div class="contact-session">
    <span id="contact-session">
      <Titulo class="mb-5" opacityTitle="Contato" texto="Contato"
    /></span>
    <b-container class="bv-example-col">
      <b-row>
        <b-col md="12" lg="6" class="left-content">
          <b-row cols="1" no-gutters align-v="center" class="h-100">
            <div>
              <div tabindex="0" class="contact subtitle">Telefone</div>
              <div tabindex="0" class="contact tel">(35)3821-4717</div>
              <div tabindex="0" class="contact subtitle">Email</div>
              <div tabindex="0" class="contact email">
                contato@stamplavras.ind.br
              </div>
            </div>
          </b-row>
        </b-col>
        <b-col lg="5" class="right-content">
          <b-card alt="entre em contato conosco">
            <b-form @submit.prevent="onSubmit">
              <b-input
                class="btn-input"
                v-model="form.nome"
                placeholder="nome"
                required
                type="text"
              ></b-input>
              <b-input
                v-mask="'(##) #####-####'"
                class="btn-input"
                v-model="form.telefone"
                placeholder="telefone"
                required
                type="tel"
              ></b-input>
              <b-input
                class="btn-input"
                v-model="form.cidade"
                placeholder="cidade"
                required
                type="text"
              ></b-input>
              <b-input
                class="btn-input"
                v-model="form.email"
                placeholder="e-mail"
                required
                type="email"
              ></b-input>
              <b-textarea
                class="btn-input msg"
                v-model="form.mensagem"
                placeholder="mensagem"
                required
                type="text"
              ></b-textarea>
              <transition name="fade" v-on:enter="enter">
                <p v-if="showSucess" class="alert-sucess">
                  Email enviado com sucesso
                  <b-icon scale="1.75" icon="check"></b-icon>
                </p>
              </transition>
              <transition name="fade" v-on:enter="enter">
                <p v-if="showError" class="alert-error">
                  Falha ao enviar email
                  <b-icon scale="1.75" icon="x">Falha ao enviar email</b-icon>
                </p>
              </transition>
              <botaoPadrao
                :formEmail="true"
                botaoBackground="#05C8EF"
                width="none"
                height="43px"
                class="btn-submit"
                fontSize="16px"
                botaoColor="black"
                botaoBorder="none"
                _type="submit"
                bordaHover="none"
                backgroundHover="#05C8EF"
                >enviar e-mail
                <b-icon scale="0.95" icon="arrow-right"></b-icon>
                <b-spinner v-if="showSpinner" small></b-spinner>
              </botaoPadrao>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Titulo from "@/components/Titulo.vue";
import formContato from "@/services/api/formContato";
import botaoPadrao from "@/components/botaoPadrao";
export default {
  name: "Contato",
  components: {
    Titulo,
    botaoPadrao,
  },
  data() {
    return {
      form: {
        nome: "",
        telefone: "",
        cidade: "",
        email: "",
        mensagem: "",
      },
      showError: false,
      showSucess: false,
      showSpinner: false,
    };
  },
  methods: {
    onSubmit() {
      this.showSpinner = true;
      const data = {
        nome: this.form.nome,
        telefone: this.form.telefone,
        cidade: this.form.cidade,
        email: this.form.email,
        mensagem: this.form.mensagem,
      };
      this.form = [];
      formContato
        .FormContato(data)
        .then(() => {
          this.nome = "";
          this.telefone = "";
          this.cidade = "";
          this.email = "";
          this.mensagem = "";
          this.showSucess = true;
        })
        .catch(() => {
          this.showError = true;
        });
    },
    enter: function() {
      var that = this;

      setTimeout(function() {
        that.showSucess = false;
        that.showError = false;
        that.showSpinner = false;
      }, 3200);
      setTimeout(function() {
        that.showSpinner = false;
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/content/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500&display=swap");
.contact-session {
  align-items: center;
  font-family: "Lato", sans-serif;
}

.left-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 100px;
}

*:focus {
  outline: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.contact.subtitle {
  font-size: 1.875em;
  color: $white-secondary;
  padding-top: 10vh;
  font-weight: 500;
}

.contact.tel {
  font-size: 1.25em;
  color: $primary;
  font-weight: 400;
}

.contact.email {
  font-size: 1.375em;
  color: $primary;
  font-weight: 400;
  margin-bottom: 7.6vh;
}

.right-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 0.8vw;
}

.card {
  width: 24rem;
  max-width: 100%;
  padding: 12px 18px 12px 18px;
  background-color: $white-primary;
  border-radius: 8px;
}

.form-control {
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0em;
  padding-left: 0em;
  background-color: transparent;
  font-size: 18px;
  font-weight: 300;
  color: $black-primary;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.input {
  background-color: transparent;
}

.btn-input {
  margin-bottom: 4.1vh;
}

.msg {
  margin-bottom: 5vh;
}

.btn-submit {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  float: right;
  margin-right: -50px;
  color: $black-primary;
  font-weight: 400;
  font-size: 18px;
  margin-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-sucess {
  color: #24b200;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-error {
  color: #ff0000;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.b-icon.bi {
  margin-left: 10px;
}

.alert {
  padding-right: 20px;
  margin-bottom: 0px;
}

@media screen and (max-width: 991px) {
  .left-content {
    padding-left: 15px;
  }

  .right-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-session {
    height: 100%;
    background-size: cover;
    padding-top: 9vh;
    padding-bottom: 9vh;
  }
}

@media screen and (max-width: 767px) {
  .card {
    max-width: 100%;
  }

  .contact-session {
    height: 100%;
    background-size: cover;
    padding-top: 9vh;
    padding-bottom: 9vh;
  }
}

@media screen and (max-width: 323px) {
  .alert-sucess {
    font-size: 0.85em;
  }

  .alert-error {
    font-size: 0.85em;
  }
}
</style>
<style>
button.close {
  font-size: 1rem;
  padding-right: 10px !important;
  padding-top: 8px !important;
  padding-left: 0px !important;
}
</style>
