<template>
  <b-container id="servicos" class="sessao">
    <Titulo class="titulo" texto="Serviços" />
    <b-row
      no-gutters
      cols-lg="4"
      cols-md="2"
      cols-sm="2"
      cols="1"
      align-h="center"
      class="cards"
      
    >
      <div v-for="(card, index) in cards" :key="index">
        <home-card-servicos
          class="mx-auto my-3"
          :nome="card.nome"
          :image="card.imagem"
          :to="card.to"
        />
      </div> </b-row
  ></b-container>
</template>

<script>
import Titulo from "@/components/Titulo";
import HomeCardServicos from "./homeCardServicos.vue";

export default {
  name: "SessaoServicos",
  components: {
    Titulo,
    HomeCardServicos,
  },
  data() {
    return {
      cards: [
        {
          nome: "Corte",
          imagem: "corte.svg",
          to: "corte",
        },
        {
          nome: "Dobra",
          imagem: "dobra.svg",
          to: "dobra",
        },
        {
          nome: "Estamparia",
          imagem: "estamparia.svg",
          to: "estamparia",
        },
        {
          nome: "Ferramentaria",
          imagem: "ferramentaria.svg",
          to: "ferramentaria",
        },
        {
          nome: "Galvanoplastia",
          imagem: "galvanoplastia.svg",
          to: "galvanoplastia",
        },
        {
          nome: "Pintura",
          imagem: "pintura.svg",
          to: "pintura",
        },
        {
          nome: "Solda",
          imagem: "solda.svg",
          to: "solda",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.sessao {
  margin-top: 0rem;

  .titulo {
    margin-bottom: 3rem;
  }
}
</style>
