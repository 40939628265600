<template>
  <router-view></router-view>
</template>

<script>
// @ is an alias to /src

export default {
  components: {},
};
</script>

<style lang="scss">
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/bootstrap-vue/src/index.scss";

@import url("https://fonts.googleapis.com/css2?family=Lato");
#app {
  font-family: "Lato";
}

a:hover {
  text-decoration: none !important;
}
</style>
