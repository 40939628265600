<template>
  <div class="navBar">
    <b-navbar id="nav" toggleable="xl">
      <!-- Logos da navBar -->
      <b-navbar-brand id="navbar-h" to="/#apresentacaoId">
        <img
          src="@/assets/navBar/logo.svg"
          class="navbar-brand logo"
          alt="Logo Estampe Lavras"
        />
      </b-navbar-brand>
      <!-- botão que chama a nav no mobile -->
      <b-navbar-toggle id="butaoTotal" target="nav-collapse">
        <template #default="{ expanded }">
          <b-icon
            v-if="expanded"
            id="iconMobile"
            class="icon"
            font-scale="2"
            color="#05C8EF"
            icon="x"
            @click="changeColorNav('notsetColor')"
          >
          </b-icon>
          <b-icon
            v-else
            color="#05C8EF"
            id="iconMobile"
            class="icon"
            font-scale="2"
            icon="list"
            @click="changeColorNav('setColor')"
          >
          </b-icon>
        </template>
      </b-navbar-toggle>
      <!-- grupo de links da navbar -->
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <!--Classe que cuida dos links-->
          <b-navbar-nav class="navbar-light" id="links" alt="Links">
            <b-nav-item
              v-for="(option, index) in options"
              :key="index"
              class="mr-xl-5"
              :to="option.link"
            >
              {{ option.titulo }}
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
//Script padrão do import
export default {
  name: "NavBar",
  data() {
    return {
      pad: 0,
    };
  },
  props: {
    options: {
      type: Array,
      default: () => {
        return [
          { titulo: "Quem Somos", link: "#quem-somos" },
          { titulo: "Serviços", link: "#servicos" },
          { titulo: "Clientes", link: "#clientes" },
          { titulo: "Localização", link: "#localizacao-section" },
          { titulo: "Contato", link: "#contact-session" },
        ];
      },
    },
  },
  methods: {
    changeColorNav(condicao) {
      if (condicao == "setColor") {
        //Mudança de cor da navbar, quando ela fica branca
        document.getElementById("nav").style.backgroundColor = "#030A12";
        document.getElementById("nav").style.boxShadow =
          "0px 4px 10px rgba(0,0,0,0.05)";
      } else {
        //Mudança de cor da navbar, quando ela fica transparente
        const scrolled = window.scrollY;
        if (scrolled > 0) {
          this.changeColorNav("setColor");
        } else {
          document.getElementById("nav").style.background = "transparent";
          document.getElementById("nav").style.boxShadow =
            "0px 0px 0px rgba(0,0,0,0.05)";
        }
      }
    },
  },
  mounted() {
    //Função para controlar se houve scroll na tela ou não
    window.addEventListener("scroll", () => {
      const scrolled = window.scrollY;
      if (scrolled > 0) {
        this.changeColorNav("setColor");
      } else {
        this.changeColorNav("notSetColor");
      }
    });

    this.pad =
      0.5 * parseFloat(getComputedStyle(document.documentElement).fontSize);
  },
};
</script>

<style scoped>
/* Deixo a navBar fixa, e com uma distância do top*/

.navBar {
  max-width: 100vw;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 300;
}

#links {
  position: relative;
  text-decoration: none;
  transition: 0.5s;
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  text-align: center;
  margin-right: 5vw;
}

.navBar >>> #links a {
  color: #ededed;
  margin: 20px auto;
  padding: 0;
  width: auto;
  text-align: center;
  display: inline-block;
  transition: 0.25s ease transform;
}
.navBar >>> #links a::after {
  content: "";
  border-top: 2px solid #05c8ef;
  width: 100%;
  position: absolute;
  display: block;
  transform: rotateY(90deg);
  transition: transform 0.25s linear;
  margin-top: 1vh;
}

.navBar >>> #links a:hover {
  color: #05c8ef;
  transform: scale(1);
}

/* .navBar >>> .navbar-collapse.show {
  background-color: #030a12;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
} */

.navBar >>> #links a:hover {
  color: #05c8ef;
  transform: scale(1);
}

.navBar >>> #links a:hover::after {
  color: #05c8ef;
  transform: rotate(0deg);
  margin-top: 1vh;
}

/* Configuro padrão do texto dos links*/
/*Espaçamento entre os textos*/

.navBar >>> .navbar-toggler {
  border: none;
}
.navBar >>> .navbar-toggler:focus {
  outline: none;
}
/*posição da logo*/
.logo {
  cursor: pointer;
  margin-left: 7.6vw;
}

@media only screen and (max-width: 1024px) {
  #links {
    margin: 0;
    font-size: 2rem;
  }

  .logo {
    width: 350px;
  }

  .icon {
    width: 4.5rem;
    height: 4.5rem;
  }

  #nav-collapse {
    background-color: #020c0e;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
  }
}

/* Mudanças realizadas para tablet */
@media (max-width: 501px) {
  #links {
    margin: 0;
    font-size: 1.2rem;
  }

  .logo {
    width: 200px;
  }

  .icon {
    width: 2rem;
    height: 2rem;
  }

  .navBar >>> #links a {
    color: #ededed;
    margin: 0.5rem auto;
    padding: 0;
    width: auto;
    text-align: center;
    display: inline-block;
    transition: 0.25s ease transform;
  }
}

@media (max-width: 325px) {
  .logo {
    margin: 0 5px;
  }
}
</style>

<style lang="scss">
html {
  scroll-behavior: smooth;
}
</style>
