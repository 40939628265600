<template>
  <div class="mapBase">
    <p tabindex="0" class="mapTitle">Rotas</p>
    <div class="mapCard">
      <b-tabs
        content-class="mt-4"
        active-nav-item-class="rounded text-white bg-active"
        no-nav-style
        justified
        alt="Secao contendo informacoes sobre a localizacao da stamplavras"
      >
        <b-tab active>
          <template #title class="tab-title">
            Belo Horizonte
            <br />MG
          </template>
          <div tabindex="0">
            <p class="tabTitle">Distância</p>
            <div class="underline"></div>
            <p class="tabDescription">
              238
              <span>km</span>
            </p>
          </div>
          <div tabindex="0">
            <p class="tabTitle">Rodovia</p>
            <div class="underline"></div>
            <p class="tabDescription">
              BR-381
              <img
                class="shield"
                src="../content/imagens/rota381.svg"
                alt="escudo da rota 381"
              />
            </p>
          </div>
          <div class="img-container">
            <img
              tabindex="0"
              class="imgMap"
              src="../content/imagens/mapaBH.png"
              alt="Imagem de um mapa indicando a distância até Belo Horizonte"
            />
          </div>
        </b-tab>
        <b-tab>
          <template #title class="tab-title">
            São Paulo
            <br />SP
          </template>
          <div tabindex="0">
            <p class="tabTitle">Distância</p>
            <div class="underline"></div>
            <p class="tabDescription">
              378
              <span>km</span>
            </p>
          </div>
          <div tabindex="0">
            <p class="tabTitle">Rodovia</p>
            <div class="underline"></div>
            <p class="tabDescription">
              BR-381
              <img
                class="shield"
                src="../content/imagens/rota381.svg"
                alt="escudo da rota 381"
              />
            </p>
          </div>
          <div class="img-container">
            <img
              tabindex="0"
              class="imgMap"
              src="../content/imagens/mapaSP.png"
              alt="Imagem de um mapa indicando a distância até São Paulo"
            />
          </div>
        </b-tab>
        <b-tab>
          <template #title class="tab-title">
            Rio de Janeiro
            <br />RJ
          </template>
          <div tabindex="0">
            <p class="tabTitle">Distância</p>
            <div class="underline"></div>
            <p class="tabDescription">
              425
              <span>km</span>
            </p>
          </div>
          <div tabindex="0">
            <p class="tabTitle">Rodovia</p>
            <div class="underline"></div>
            <p class="tabDescription">
              BR-040
              <img
                class="shield"
                src="../content/imagens/rota404.svg"
                alt="escudo da rota 040"
              />
            </p>
          </div>
          <div class="img-container">
            <img
              tabindex="0"
              class="imgMap"
              src="../content/imagens/mapaRJ.png"
              alt="mapa RJ para stamplavras"
            />
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.mapCard .nav-link {
  color: #000000;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mapCard .nav-link.active.rounded.text-white.bg-active {
  background-color: #051b30;
}
</style>

<style lang="scss" scoped>
.mapBase {
  line-height: 15px;
  float: right;
  margin-top: 3vw;
  z-index: 1;
  width: 24rem;
  height: 38rem;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #ffffff;

  max-width: 100%;
}

.mapTitle {
  /*centralizador abaixo*/
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-left: 20px;
  /*---------------------*/
  position: relative;

  top: 14px;
  font-size: 24px;
  line-height: 29px;
  color: #08414d;
}

.mapCard {
  /*centralizador abaixo*/
  display: block;
  margin-left: auto;
  margin-right: auto;
  /*---------------------*/

  position: relative;
  top: 13px;
  width: 90%;
  height: 250px;
  background: #ffffff;
  color: #000000;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.tabTitle {
  position: relative;
  padding-left: 14px;
  font-size: 14px;
}

.tab-tile {
  font-size: 0.75rem;
}

.tabDescription {
  position: relative;
  padding-bottom: 1.3rem;
  padding-left: 0.9rem;
  font-size: 1.9rem;

  margin-bottom: 0.2rem !important;
}

.tabDescription span {
  font-size: 1.9rem;
}

.underline {
  position: relative;
  top: -15px;
  left: 14px;
  width: 70px;
  height: 0px;
  border: 1px solid #05c8ef;
}

.img-container {
  display: flex;
  justify-content: center;
}

.imgMap {
  width: 18rem;
  max-width: 90%;
}

@media screen and (max-width: 991px) {
  .mapBase {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
