<template>
  <div id="apresentacaoId">
    <div>
      <b-img
        tabindex="0"
        class="logoTitulo"
        :src="require('../assets/apresentacao/logo.png')"
        alt="Logo Estampe Lavras"
      />
      <p tabindex="0" class="texto">
        A estamparia metálica mais completa do sul de Minas Gerais
      </p>
    </div>
    <div>
      <b-img
        style="margin-right: 1.4rem"
        class="icon"
        tabindex="0"
        :src="require('../assets/apresentacao/iatf.svg')"
        alt="certificação IATF - 16949"
      />
      <b-img
        class="icon"
        tabindex="0"
        :src="require('../assets/apresentacao/v.svg')"
        alt="Autorização Ambiental"
      />
    </div>
    <Botao href="#quem-somos"> Conheça-nos </Botao>
  </div>
</template>

<script>
import Botao from "../components/botaoPadrao";
export default {
  components: {
    Botao,
  },
  name: "Apresentacao",
};
</script>

<style lang="scss" scoped>
@import "@/content/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500&display=swap");

/* Imagem de fundo para computador e tablets */
#apresentacaoId {
  background-image: url("../assets/apresentacao/apresentacao.jpg");
  background-position: center;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  padding-top: 3rem;
  padding-bottom: 3rem;

  color: $white-secondary;
  text-align: center;
  font-size: 1rem;
}

.logoTitulo {
  margin-top: 20vh;
  width: 28rem;
  max-width: 60vw;
}

.texto {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}

.icon {
  width: 5rem;
  opacity: 0.7;
  transition: opacity 200ms;

  margin-bottom: 20px;
}

.icon:hover {
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  #apresentacaoId {
    font-size: 1.725rem;

    .botao {
      font-size: 2rem !important;
      line-height: 2.1rem;
    }
  }
  .logoTitulo {
    margin-top: 6vh;
    width: 40rem;
  }
  .icon {
    width: 8rem;
  }
}

@media screen and (max-width: 500px) {
  #apresentacaoId {
    font-size: 1rem;
    margin-right: 0.125rem;
    margin-left: 0.125rem;

    .botao {
      font-size: 1rem !important;
      line-height: 1.1rem;
    }
  }
  .logoTitulo {
    width: 200rem;
  }
  .icon {
    width: 4rem;
  }
}
</style>
