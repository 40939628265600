<template>
  <div>
    <div class="my-4">
      <b-container>
        <b-row align-h="center">
          <b-img :src="require('../assets/footer/logo_stamp.png')"></b-img>
        </b-row>
      </b-container>
    </div>
    <div class="footers">
      <b-container fluid>
        <b-row align-v="end" class="my-2">
          <b-col class="text-right mr-2">
            <b-link href="https://www.compjunior.com.br/" target="_blanck">
              <b-img
                :src="require(`@/assets/footer/footer.svg`)"
                :width="135"
                :height="35"
                alt="Logo comp junior"
              ></b-img>
            </b-link>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: inherit;
}

.text-right {
  margin-left: auto;
}

.footers {
  position: absolute;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(5, 25, 44, 0) 0%,
    rgba(5, 25, 44, 0.2) 12.29%,
    #05192c 100%
  );
}
</style>
<script>
export default {
  name: "Footer",
};
</script>
