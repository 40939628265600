<template>
  <b-button
    v-if="formEmail"
    @click="$emit('click')"
    :type="_type"
    class="botao"
    :style="styleBotao"
    :href="href"
    ><slot></slot>
  </b-button>
  <router-link
    v-else
    @click="$emit('click')"
    :type="_type"
    class="botao"
    :style="styleBotao"
    :to="href"
    ><slot></slot
  ></router-link>
</template>

<script>
/* 
    PROPS:
        -> botaoColor: cor do botao
        -> botaoBackground: propiedade css do background
        -> botaoBorder: borda do botao (ex. 1px solid white )
        -> width: comprimento em px
        -> height: altura em px
        -> shadow: sombra

        para colocar o texto basta apenas escrever o conteuno entre as tags do botaoPadrao
*/

export default {
  name: "botaoPadrao",
  props: {
    formEmail: {
      type: Boolean,
      default: false,
    },
    botaoColor: {
      type: String,
      default: "#05c8ef",
    },
    botaoBackground: {
      type: String,
      default: "transparent",
    },
    botaoBorder: {
      type: String,
      default: "1.5px solid white",
    },
    fontSizeProp: {
      type: String,
      default: "15px",
    },
    widthProp: {
      type: String,
      default: "none",
    },
    heightProp: {
      type: String,
      default: "none",
    },
    shadow: {
      type: String,
      default: "none",
    },
    _type: {
      type: String,
    },
    backgroundHover: {
      type: String,
      default: "#06B6DA",
    },
    colorHover: {
      type: String,
      default: "#FFFFFF",
    },
    bordaHover: {
      type: String,
      default: "1.5px solid #069AB8",
    },
    href: {
      type: String,
    },
  },
  data() {
    return {
      styleBotao: {
        color: this.botaoColor,
        background: this.botaoBackground,
        border: this.botaoBorder,
        fontSize: this.fontSizeProp,
        width: this.widthProp,
        height: this.heightProp,
        "--colorHover": this.colorHover,
        "--backgroundHover": this.backgroundHover,
        "--bordaHover": this.bordaHover,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.botao {
  border-radius: 50rem;
  line-height: 15px;
  width: none;
  padding: 10px 25px;
}

.botao:hover {
  transition: all 0.2s ease-in-out;
  color: var(--colorHover) !important;
  background: var(--backgroundHover) !important;
  border: var(--bordaHover) !important;
}
</style>
