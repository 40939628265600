<template>
  <div id="quem-somos" class="quem-somos">
    <Titulo texto="A Stamplavras" opacityTitle="Quem somos?" />
    <b-container class="container">
      <b-row no-gutters>
        <p tabindex="0">
          A Stamplavras é uma empresa criada no ano de 2004, na cidade de Lavras, região do Sul de Minas Gerais. Com o intuito de atender empresas do segmento automotivo, como a Cofap. 
        </p>
        <p tabindex="0">
          Com foco na qualidade e na satisfação total do cliente, consolidou seu nome no mercado. Dessa forma, atua hoje em vários segmentos e possui em sua carteira marcas reconhecidas no mercado mundial de veículos, como a General Motors, Volkswagen, Fiat, Ford, Nissan, Renault e também, a Epiroc, empresa sueca com presença mundial, atuante no mercado de mineração e construção. 
        </p>
        <p tabindex="0">
          Além disso, a Stamplavras atende também o mercado motociclístico e de movelaria. Tudo isso, devido a uma estrutura moderna e ágil, pronta para desenvolver e criar produtos para os mais variados tipos de mercado. 
        </p>
      </b-row>
      <b-row cols="1" cols-md="2" cols-lg="4" align-h="center">
        <b-col>
          <b-row cols="1" no-gutters align-h="center" align-v="end">
            <div tabindex="0" class="image caminhao">
              <img
                :src="require('../assets/quemSomos/caminhao.svg')"
                alt="imagem com caminhão"
              />
            </div>
            <p tabindex="0">Frota própria de caminhões disponíveis para o transporte</p>
          </b-row>
        </b-col>
        <b-col>
          <b-row cols="1" no-gutters align-h="center">
            <div tabindex="0" class="image predio">
              <img
                class="mx-auto"
                style="position: relative"
                :src="require('../assets/quemSomos/empilhadeira.svg')"
                alt="imagem com prédio"
              />
            </div>
            <p tabindex="0">Empilhadeiras auxiliando as demandas diárias de produção</p>
          </b-row>
        </b-col>
        <b-col>
          <b-row cols="1" no-gutters align-h="center">
            <div tabindex="0" class="image empilhadeira">
              <img
                :src="require('../assets/quemSomos/painel_eletrico.png')"
                alt="imagem com empilhadeira"
              />
            </div>
            <p tabindex="0">
              Cabine primária de alta tensão
            </p>
          </b-row>
        </b-col>
         <b-col>
          <b-row cols="1" no-gutters align-h="center">
            <div tabindex="0" class="image empilhadeira">
              <img
                :src="require('../assets/quemSomos/ferramentas.png')"
                alt="imagem com empilhadeira"
              />
            </div>
            <p tabindex="0">
              Equipamentos de última geração
            </p>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Titulo from "@/components/Titulo.vue";
export default {
  name: "QuemSomos",
  components: {
    Titulo,
  },
};
</script>

<style lang="scss" scoped>
.quem-somos {
  background: url("../assets/quemSomos/bg.svg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.container {
  color: #ededed;
  font-size: 0.96rem;
  line-height: 1.2rem;
  padding: 6rem 12rem 0;

  .row {
    text-align: center;
  }

  > .row {
    text-align: left;

    &:first-child {
      margin-bottom: 1rem;
    }
  }

  .image {
    height: 74px;
    margin-bottom: 1rem;

    &.caminhao {
      img {
        margin-top: 27px;
        width: 72px;
        height: 47px;
      }
    }

    &.predio {
      img {
        width: 72px;
        height: 74px;
      }
    }

    &.empilhadeira {
      img {
        margin-top: 20px;
        height: 54px;
      }
    }

    & + p {
      padding: 0 0rem;
      max-width: 192px;
    }
  }
}

@media screen and (max-width: 991px) {
  .container {
    padding: 3rem 15px 0;
  }
}
</style>
