<!-- 
props:
  text: o que vai tá escrito no titulo
  opacityTitle: titulo repetido do lado
  servico: telas de serviço (botão voltar e texto repetito com opacidade)
-->

<template>
  <div class="todo" :id="'titulo' + _uid">
    <div id="titBut">
      <div class="tit1" id="divBotao" v-if="servico">
        <div class="content">
          <div class="opacity-title">
            <p>
              {{ opacityTitle }}<br />
              {{ opacityTitle }}<br />
              {{ opacityTitle }}<br />
              {{ opacityTitle }}<br />
              {{ opacityTitle }}
            </p>
          </div>
        </div>
        <botao-padrao
          id="botao"
          v-if="servico"
          href="/#servicos"
          :heightProp="'40px'"
        >
          <img src="@/assets/seta.svg" id="seta" /> Voltar
        </botao-padrao>
      </div>
      <div class="tit2">
        <div
          tabindex="0"
          :class="['content-title', 'titulo' + _uid]"
          :style="styleText"
        >
          {{ texto }}
        </div>
      </div>
      <div class="tit1" v-if="servico"></div>
    </div>
  </div>
</template>

<script>
import BotaoPadrao from "./botaoPadrao.vue";
export default {
  name: "Titulo",
  components: {
    BotaoPadrao,
  },
  props: {
    texto: {
      type: String,
      required: true,
    },
    opacityTitle: {
      type: String,
      required: false,
    },
    servico: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      styleText: {
        color: this.TextColor,
      },
      apareceu: false,
    };
  },
  methods: {
    Shows() {
      let elem = document.getElementsByClassName("titulo" + this._uid)[0];
      if (elem) {
        this.apareceu = true;
        elem.style.right = 0 + "vw";
        elem.style.opacity = 1;
        if (this.servico) document.getElementById("botao").style.opacity = 1;
      }
    },
  },
  mounted() {
    //se não é uma seção de serviços, não tem volta
    if (!this.servico) {
      window.addEventListener("scroll", () => {
        const el = document.getElementById("titulo" + this._uid);
        if (el) {
          let topPostition = el.offsetTop;

          if (
            !this.apareceu &&
            window.scrollY + window.innerHeight > topPostition
          )
            this.Shows();
        }
      });
    } else {
      if (!this.apareceu) {
        this.Shows();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/content/colors.scss";

@font-face {
  font-family: Monument Extended;
  src: url("../assets/Fontes/MonumentExtended-Regular.otf");
}

.todo {
  position: relative;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
}

.opacity-title {
  font-family: Monument Extended;
  position: absolute;
  font-size: 2.5em;
  line-height: 1em;
  z-index: -100;
  opacity: 0.8;
  p {
    background: -webkit-linear-gradient(#04121f, #030a12);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

#divBotao {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.content-title {
  font-family: Monument Extended;
  font-size: 3em;
  text-align: center;
  color: #ededed;
  z-index: 100;
  position: relative;
  right: 80vw;
  opacity: 0;
  transition: all 1s;
  transition-timing-function: ease;
}

#botao {
  opacity: 0;
  transition: all 1s;
  transition-timing-function: ease;
}

#titBut {
  display: flex;
}
.tit1 {
  flex-grow: 1;
  flex-basis: 0;
}
.tit2 {
  flex-grow: 2;
  flex-basis: 0;
}

.div-voltar {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20%;
  width: 100%;
  height: 100%;
  z-index: 250;
}

#seta {
  font-size: 4em;
}
@media screen and (max-width: 834px) {
  .opacity-title {
    display: none;
  }
  .content-title {
    font-size: 2.4em;
  }
  .div-voltar {
    padding-left: 7.5%;
  }
}
@media screen and (max-width: 415px) {
  .content-title {
    font-size: 1.8em;
  }
  .div-voltar {
    justify-content: center;
    top: 100%;
    padding-left: 0%;
  }
  #titBut {
    flex-direction: column-reverse;
  }
}
</style>
