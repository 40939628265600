import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/servicos",
    name: "Servicos",
    component: () =>
      import(/* webpackChunkName: "servicos" */ "../views/servicos/"),
    redirect: "/",
    children: [
      {
        path: "corte",
        name: "corte",
        component: () =>
          import(
            /* webpackChunkName: "corteservicos" */ "../views/servicos/corte.vue"
          ),
      },
      {
        path: "dobra",
        name: "dobra",
        component: () =>
          import(
            /* webpackChunkName: "dobraservicos" */ "../views/servicos/dobra.vue"
          ),
      },
      {
        path: "estamparia",
        name: "estamparia",
        component: () =>
          import(
            /* webpackChunkName: "estampariaservicos" */ "../views/servicos/estamparia.vue"
          ),
      },
      {
        path: "ferramentaria",
        name: "ferramentaria",
        component: () =>
          import(
            /* webpackChunkName: "ferramentariaservicos" */ "../views/servicos/ferramentaria.vue"
          ),
      },
      {
        path: "galvanoplastia",
        name: "galvanoplastia",
        component: () =>
          import(
            /* webpackChunkName: "galvanoplastiaservicos" */ "../views/servicos/galvanoplastia.vue"
          ),
      },
      {
        path: "pintura",
        name: "pintura",
        component: () =>
          import(
            /* webpackChunkName: "pinturaservicos" */ "../views/servicos/pintura.vue"
          ),
      },
      {
        path: "solda",
        name: "solda",
        component: () =>
          import(
            /* webpackChunkName: "soldaservicos" */ "../views/servicos/solda.vue"
          ),
      },
    ],
  },
];

function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  } else if (to.hash) {
    const position = { selector: "", offset: { x: 0, y: 0 } };

    position.selector = to.hash;
    position.offset.y =
      document.getElementById("navbar-h").clientHeight + 16 + 20;

    return position;
  }
  return { x: 0, y: 0 };
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior,
});

export default router;
