<template>
  <div id="localizacao">
    <div class="gradient"></div>
    <b-container>
      <div id="localizacao-section">
        <Titulo texto="Localização" opacityTitle="Localização" />
      </div>
      <b-row>
        <b-col class="text-column" offset-lg="1" lg="4">
          <div class="text">
            <p tabindex="0">
              A Stamplavras conta com uma localização privilegiada, com a sede
              próxima aos principais polos industriais do sudeste do país
            </p>
            <div>
              <div tabindex="0" class="address">
                <div>Lavras - MG</div>
                <div>Rua Ezio Áurelio Cavazza, 102</div>
                <div>Distrito Industrial Sylvio Menicucci</div>
                <div>37205-852</div>
              </div>
              <a
                href="https://www.google.com.br/maps/place/Stamplavras+Produ%C3%A7%C3%A3o+de+L%C3%A2minas+de+A%C3%A7o/@-21.2676161,-44.9876791,17z/data=!3m1!4b1!4m5!3m4!1s0x94caacb9d20e55d7:0x3d8a0fdec8b59c87!8m2!3d-21.2676211!4d-44.9854904"
                target="_blank"
                rel="noopener noreferrer"
              >
                <botaoPadrao tabindex="-1">Ver no Google Maps</botaoPadrao>
              </a>
            </div>
          </div>
        </b-col>

        <b-col offset-lg="1" lg="5">
          <CardMapa />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Titulo from "./Titulo.vue";
import CardMapa from "./cardMapa.vue";
import botaoPadrao from "./botaoPadrao.vue";
export default {
  components: {
    Titulo,
    CardMapa,
    botaoPadrao,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#localizacao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 1rem;
}

/* Faixa no meio */
.gradient {
  width: 100%;
  height: 740px;
  position: absolute;
  margin-top: 385px;
  background-size: 100% 70%;
  background-image: linear-gradient(
    270deg,
    #072a4a 0%,
    rgba(7, 42, 74, 0) 60.73%,
    rgba(7, 42, 74, 0) 100%
  );
  background-repeat: no-repeat;
}

.text-column {
  padding-top: 7rem;
  padding-bottom: 5rem;
}
.text {
  /* Container: */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* Texto: */
  color: #fafafa;
  font-size: 1.125em;
}

.address {
  margin-top: 1rem;
  margin-bottom: 1.8rem;
}

@media screen and (max-width: 992px) {
  .gradient {
    margin-top: 755px;
  }

  .text-column {
    padding-top: 3rem;
  }
}
@media screen and (max-width: 768px) {
  .gradient {
    margin-top: 760px;
  }
}
</style>
