import axios from "axios";

const url =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://envio-de-email-do-site-stamplavras.vercel.app/";

export default () => {
  return axios.create({
    baseURL: url,
  });
};
