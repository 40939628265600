<template>
  <div id="app">
    <NavBar />
    <Apresentacao class="pb-5" />
    <QuemSomos class="distanciamento" />
    <sessao-servicos class="distanciamento" />
    <cardParceiros id="clientes" class="distanciamento" />
    <Localizacao class="distanciamento" />
    <Contato class="distanciamento" />
    <Footer />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Apresentacao from "@/components/apresentacao.vue";
import QuemSomos from "@/components/QuemSomos.vue";
import cardParceiros from "@/components/cardParceiros.vue";
import sessaoServicos from "@/components/sessaoServicos.vue";
import Localizacao from "@/components/Localizacao.vue";
import Contato from "@/components/Contato.vue";
import Footer from "@/components/footer.vue";

export default {
  name: "App",
  components: {
    NavBar,
    Apresentacao,
    QuemSomos,
    cardParceiros,
    sessaoServicos,
    Localizacao,
    Contato,
    Footer,
  },
};
</script>

<style scoped>
#app:first-child {
  z-index: 300;
}

.distanciamento {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}
</style>