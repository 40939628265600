<template>
  <router-link tabindex="0" class="main" :to="'/servicos/' + to">
    <img
      :src="require(`@/assets/Servicos/icons/${image}`)"
      :alt="'clique para saber mais sobre'"
    />
    <h3>{{ nome }}</h3>
    <div class="saibaMais">
      <router-link tabindex="0" :to="'/servicos/' + to">
        Saiba mais
      </router-link>
      <b-icon scale="0.95" icon="arrow-right"></b-icon>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "cardServicosHome",
  props: {
    nome: {
      type: String,
      default: "Nome",
      required: true,
    },
    image: {
      type: String,
      default: "corte.svg",
      required: true,
    },
    to: {
      type: String,
      default: "corte",
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  color: #fff;
  border: 3px solid #122f4f;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 210px;
  background: radial-gradient(
    circle,
    rgba(0, 212, 255, 0) 0%,
    rgba(0, 0, 0, 0.7539390756302521) 100%
  );
  transition: background-color 0.3s;
  cursor: pointer;

  img {
    padding: 1rem 0;
  }

  h3 {
    margin: 0 0 0.25rem;
  }

  .saibaMais {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: opacity 0.3s;
    opacity: 0;

    button {
      margin: 0;
      background: transparent;
      color: #fff;
      border: none;
    }
  }

  &:hover {
    background-color: #14447b;

    .saibaMais {
      opacity: 1;
    }
  }
}
</style>
